import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./views/pages/pages.module').then(m => m.PagesModule)
      },
       {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'services',
        loadChildren: () => import('./views/services/services.module').then(m => m.servicesModule)
      },
      {
        path: 'team',
        loadChildren: () => import('./views/team/team.module').then(m => m.teamModule)
      },
      {
        path: 'blogs',
        loadChildren: () => import('./views/blogs/blogs.module').then(m => m.blogModule)
      },
      {
        path: 'partners',
        loadChildren: () => import('./views/partners/partners.module').then(m => m.partnersModule)
      },
      {
        path: 'contactUS',
        loadChildren: () => import('./views/contactUs/contactUS.module').then(m => m.contactUsModule)
      },
      {
        path: 'about',
        loadChildren: () => import('./views/about/about.module').then(m => m.aboutModule)
      },
      {
        path: 'history',
        loadChildren: () => import('./views/history/history.module').then(m => m.historyModule)
      },
      {
        path: 'aboutUs',
        loadChildren: () => import('./views/aboutUs/aboutUs.module').then(m => m.aboutUsModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'contactRequests',
        loadChildren: () => import('./views/contactRequests/contactRequests.module').then(m => m.ContactRequestModule)
      },
      {
        path: 'hireMe',
        loadChildren: () => import('./views/hireME/hireMe.module').then(m => m.hireMeModule)
      },
      {
        path: 'emails',
        loadChildren: () => import('./views/emails/emails.module').then(m => m.EmailModule)
      },
      {
        path: 'addProduct',
        loadChildren: () => import('./views/addProduct/addProduct.module').then(m => m.addProductModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}

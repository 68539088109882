import { Component, AfterViewInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements  AfterViewInit { 
  error: any;
  constructor(private _router: Router, private _authSrv: AuthService) { }
  ngAfterViewInit(){
    
  }

  login(formData?) {
    this._authSrv.login(formData)
    .then(
      (success) => {
        console.log(success)
        this._router.navigate(['/home']);
      }).catch(
      (err) => {
        this.error = err;
      })
    ;
  }
}

import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/dashboard',
  //   icon: 'icon-speedometer'},
    // {
    //   name: 'Pages',
    //   url: '/pages',
    //   icon: 'icon-speedometer'},
      {
        name: 'Products',
        url: '/products',
        icon: 'icon-speedometer'}
      //  , {
      //     name: 'contact Us',
      //     url: '/contactUS',
      //     icon: 'icon-speedometer'}
          , 
          {
            name: 'about Us',
            url: '/about',
            icon: 'icon-speedometer'}
            // ,{
            // name: 'our History',
            // url: '/history',
            // icon: 'icon-speedometer'}
              ,
              {
            name: 'home',
            url: '/home',
            icon: 'icon-speedometer'},
            {
            name: 'blogs',
            url: '/blogs',
            icon: 'icon-speedometer'},
            // {
            // name: 'services',
            // url: '/services',
            // icon: 'icon-speedometer'},
              {
            name: 'partners',
            url: '/partners',
            icon: 'icon-speedometer'},
               {
            name: 'team',
            url: '/team',
            icon: 'icon-speedometer'},
             {
              name: 'Contacts Box',
              url: '/contactRequests',
              icon: 'icon-speedometer'}
              ,
             {
              name: 'Hire Me',
              url: '/hireMe',
              icon: 'icon-speedometer'}
              ,
              {
               name: 'Subscribers',
               url: '/emails',
               icon: 'icon-speedometer'}


  ];
